import { Box, Button, Rating, Stack, Table, TableCell, TableRow } from "@mui/material";
import { useSDK, Wine} from "./sdk"
import ModalDialog from "./ModalDialog";
import React from "react";

interface WineProfileProps {
    wine?: Wine | null | undefined;
    open: boolean;
    onClose: () => void;
}

const WineProfile: React.FC<WineProfileProps> = ({ open, wine, onClose }) => {
    const sdk = useSDK();
    
    if(!wine) return null;    
    const getAttribute = (name:string) => wine.attributes.find((attribute) => attribute.name === name)?.value;

    
    const handleOpen = async () => {
        await sdk.openWine(wine.upc);
    }

    const handleFinish = async () => {
        await sdk.finishWine(wine.upc);
    }

    return  <ModalDialog open={open} onClose={()=>onClose()} width={700} disableAutoFocus>
        <Stack direction={"row"}>
                <Box>
                    <img src={wine.image} alt={wine.name} style={{ height: '75vh' }} />
                </Box>
                <Box>
                    <span>{getAttribute("Country")}, {getAttribute("Region")} | {getAttribute("Grape")}</span>
                    <h1>{wine.name}</h1>
                    <h2>{wine.price}$</h2>
                    <Stack direction={"row"}>
                        <Rating name="simple-controlled" value={wine.rating/10} max={10} size={"large"} />
                    </Stack>
                    <Stack direction={"row"} sx={{gap:2, padding:1}}>
                        <Button variant="contained" onClick={handleOpen} disabled={wine.isCurrentlyOpen} size='large'>Ouvrir</Button>
                        <Button variant="contained" onClick={handleFinish} disabled={!wine.isCurrentlyOpen} size='large'>Finir</Button>
                    </Stack>
                    <Stack direction={"row"}>
                        Rating of {wine.rating}%
                    </Stack>
                </Box>
            </Stack>
        </ModalDialog>
    ;
}

export default WineProfile;